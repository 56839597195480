/* eslint-disable */

export default () => {
  $(document).on('gform_post_render', function () {
    // Add click event to plugin tooltips
    $('.gftt-content').closest('label').attr('data-rhcmodule','tooltip');

    // Multi-select JS via Tom-Select
    document.querySelectorAll('.multi-select select').forEach((el)=>{
      let settings = {
        placeholder: 'Click here for options',
        hidePlaceholder: true
      };
      new TomSelect(el,settings);
    });
  });
}